import React from 'react';
import Skeleton from 'react-loading-skeleton';
import box from '../images/box.png';
import './Popups.scss';

const ShippingDetails = (props) => {

    const { loading, customerName, addressLineOne, addressLineTwo, addressCity, addressState, addressZip, addressCountry, shippingDetailsHeading, serviceUsed, estimatedShippingDate, estimatedDeliveryNote } = props;

    const estimatedShippingDateContent = <div id="estimated-shipping-date-wrapper"><div id="estimated-delivery-date-title">ESTIMATED DELIVERY DATE:&nbsp;</div><div>{`${estimatedShippingDate}`}</div></div>;

    return(
        <div id="shipping-details-wrapper-1">
            <div id="shipping-details-wrapper-2">
                <div id="shipping-heading-wrapper">
                    <div id="shipping-details-heading">
                        { shippingDetailsHeading || <Skeleton width={200}/>}
                    </div>
                    { loading ? 
                        null : 
                        <img src={box} id="shipping-icon" alt="Shipping Icon"/> }
                </div>
                <div id="shipping-address-wrapper">
                    <div className="shipping-address">{customerName || <Skeleton count={5} width={300}/>}</div>
                    <div className="shipping-address">{addressLineOne}</div>
                    <div className="shipping-address">{addressLineTwo}</div>
                    {loading ? <div style={{color:"white"}}>.</div> : <div className="shipping-address">{addressCity} {`${addressState} ${addressZip}`}</div>}
                    <div className="shipping-address">{addressCountry}</div>
                </div>
                {serviceUsed === "Etsy" && estimatedShippingDate ? estimatedShippingDateContent : null}
                {estimatedShippingDate ? <div id="shipping-delivery-note">{estimatedDeliveryNote}</div> : null}
            </div>
        </div>
    )
}

export default ShippingDetails;
