import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ProofDetails = (props) => {

    const { loading, pageType, bookTwoCoverColor, bookOneCoverColor, proofCoverColor, proofFoilColor, bookSize } = props;

    return(
        <div style={{display: "flex", flexDirection:"column", alignSelf:"flex-start", padding:"5px 10px 5px 10px", whiteSpace:"pre", width:"100%"}}>
            <div style={{display: "flex", flexDirection: "column", lineHeight:"2rem"}}> 
                { loading ? <Skeleton width={200}/> : 
                    bookSize !== null && bookSize !== "N/A" ?
                        <div style={{display: "flex", placeContent:"space-between"}}>
                            <span className="headTitle" >Book size:</span>
                            <span className="headValue">{bookSize}</span>
                        </div> : null
                }
                { loading ? <Skeleton /> : 
                    pageType !== null && pageType["Page Style"] !== "N/A" ? 
                    <div style={{display: "flex", placeContent:"space-between"}}>
                        <span className="headTitle">Page type:</span>
                        <span className="headValue">{pageType["Page Count"]} {pageType["Page Style"]}</span>
                    </div> : null
                }
                { loading ? <Skeleton /> : 
                    bookOneCoverColor ? 
                        <div style={{display: "flex", placeContent:"space-between"}}>
                            <span className="headTitle">Book 1 color:</span>
                            <span className="headValue">{bookOneCoverColor}</span>
                        </div> : null
                }
                { loading ? <Skeleton /> : 
                    bookTwoCoverColor ? 
                        <div style={{display: "flex", placeContent:"space-between"}}>
                            <span className="headTitle">Book 2 color:</span>
                            <span className="headValue">{bookTwoCoverColor}</span>
                        </div> : null
                }
                { loading ? <Skeleton /> : 
                    proofCoverColor ? 
                        <div style={{display: "flex", placeContent:"space-between"}}>
                            <span className="headTitle" >Background Color:</span>
                            <span className="headValue">{proofCoverColor}</span>
                        </div> : null
                }
                { loading ? <Skeleton/> : 
                    proofFoilColor ? 
                        <div style={{display: "flex", placeContent:"space-between"}}>
                            <span className="headTitle" >Foil color:</span>
                            <span className="headValue">{proofFoilColor}</span>
                        </div> : null
                }
            </div>
        </div>
    );
};

export default ProofDetails;
