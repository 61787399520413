import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import Skeleton from 'react-loading-skeleton';
import './Popups.scss';
// import { useFormikContext } from 'formik';
// import 'reactjs-popup/dist/index.css';

const ApproveButton = (props) => {

    //const approveButtonContent = sendingApproved ? <img src="https://i.imgur.com/tEToq5L.gif" style={{width:"30px", height:"30px"}}/> : "Approve";
    //const [request, setRequest] = useState(""); // 
    //const [email, setEmail] = useState("");
    //const [approveCounter, setApproveCounter ] = useState(0); 

    const { formValues, proofResponseRID, orderLineItems, approvedFileName, orderNumber, ATP, setPageView, selectedImage, setWrapperHeight, approvalPopupHeading, approvalPopupText } = props;

    const [understood, setUnderstood] = useState(false); // Understood checkbox state
    const [sendingApproved, setSendingApproved] = useState(false); // Whether the API request is currently sending
    const [approveButtonContent, setApproveButtonContent] = useState("Approve"); //Content shown on the approve button
    const [displayError, setDisplayError] = useState("none"); // Shows/hides error message if API call fails
    const [imageLoaded, setImageLoaded] = useState(false); // Used to toggle loading skeleton if selected image hasn't loaded

    const buttonDisabled = !understood || approveButtonContent !== "Approve" ? true : false; // Disabled button if 'Understood' is not checked
    const approvalStatus = ATP === "ATP" ? "Approved (ATP)" : "Approved"; // If this is an ATP order, sets ATP approval status
    const skeletonDisplay = imageLoaded ? "none" : "block"; // Loading skeleton shown while image is loading
    const imageDisplay = imageLoaded ? "block" : "none"; // Replaces loading skeleton with image once loaded

    //!!!!!!!!!  START API REQUEST !!!!!!!!!!!!!!!!\\
    
    const approveUpdateProofResponse = () => {
                
        //console.log(Object.keys(formValues)); Commented out Sept 24 2021
        const selectedKeys = Object.keys(formValues);
        const selectedValues = Object.values(formValues);
        //console.log(selectedKeys); Commented out Sept 24 2021
        setSendingApproved(true);
        setApproveButtonContent(<img alt="Loading spinner" src="https://i.imgur.com/tEToq5L.gif" style={{width:"30px", height:"30px"}}/>);
    ////:orderid/approve/:approvalStatus/:approvedFileName
    //fetch(`http://localhost:8082/${orderNumber}/approve/${approvalStatus}/${approvedFileName}`, {
        fetch(`/api/${orderNumber}/approve/${approvalStatus}/${approvedFileName}`, {
                },
        )
            .then(response => response.json())
            //.then(data => console.log(data)) Commented out Sept 24 2021
            .then(() => setSendingApproved(false)) 
            .then(() => setApproveButtonContent("Approved!"))
            .then(() => console.log('Record successfully updated!'))
            .then(() => setPageView("Approved"))
            .then(() => setWrapperHeight("100vh"))
            .catch((error) => {setApproveButtonContent("Approve"); setDisplayError("block"); console.log(error)});
    };

    //!!!!!!!!!  END API REQUEST !!!!!!!!!!!!!!!!\\
    
    return(
        <Popup trigger={<button className="approveButton"> Approve </button>} modal> 
        {/*Change button class to approvebutton to revert */}
            {close => (
                <div className="content">
                    <div id="close-wrapper">
                        <div id="popup-header">
                            {approvalPopupHeading}
                        </div>
                        <button className="close" onClick={close}>
                            &times;
                        </button>
                    </div>
                    <p id="approve-modal-content">
                        {approvalPopupText}
                    </p>
                    <div id="selected-proof-image-wrapper">
                        <img id="selected-proof-image" style={{display:`${imageDisplay}`}} alt="Your selected proof" src={selectedImage} onLoad={() => setImageLoaded(true)}/>
                        <Skeleton width={200} height={80} style={{display:`${skeletonDisplay}`}}/>
                        <p id="selected-proof-image-text">Your selected proof</p>
                    </div>
                    <div id="checkbox-wrapper">
                        <input 
                            name="understood"
                            type="checkbox"
                            id="understood-checkbox"
                            checked={understood}
                            onClick={() => setUnderstood(!understood)}>
                        </input>
                        <label for="understood-checkbox" className="understood-checkbox-container">
                            <span>
                                I understand
                            </span>
                        </label>
                        <div id="approve-error-message" style={{display:`${displayError}`}}>
                            Error: Please email us if you are not redirected to the 'Thank you' page.
                        </div>    
                        <div className="popup-approve-button">
                            <button disabled={buttonDisabled} className="approveButton" onClick={() => approveUpdateProofResponse()}>
                                {approveButtonContent}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    );
};


export default ApproveButton;