import React from 'react';
import ProofBlock from './ProofBlock';
import Skeleton from 'react-loading-skeleton';
import './Popups.scss';

const ProofSection = (props) => {

    // V A R I A B L E S //

    const { loading, lineItems, orderNumber, orderNumberHeading, reviewProofsHeading, name, addressLineOne, addressLineTwo, addressCity, addressState, addressZip, addressCountry, shippingDetailsHeading, serviceUsed, estimatedShippingDate, setSelectedImage } = props;

    const proofBlockForLineItem = lineItems["Proof"] && lineItems["Type"] === "Vow Book" ? 
        <ProofBlock 
            loading={loading}
            setSelectedImage={setSelectedImage}

            title={lineItems["Type"]}
            foilColor={lineItems["Foil Color"]}
            
            // bookTwoCoverColor={lineItems.herVowsCoverColor}
            pageType={lineItems}
            bookSize={lineItems["Size"]}
            proofOneImage={lineItems["Proof"]}
            proofOneBookOneCoverColor={lineItems["Background"]}
            proofOneBookTwoCoverColor={lineItems["Background 2"]}

            proofTwoImage={lineItems["Proof (2)"]}
            proofTwoBookOneCoverColor={lineItems["Proof (2) Background"]}
            proofTwoBookTwoCoverColor={lineItems["Proof (2) Background 2"]}
            proofTwoFoilColor={lineItems["Proof (2) Foil Color"]}

            proofThreeImage={lineItems["Proof (3)"]}
            proofThreeBookOneCoverColor={lineItems["Proof (3) Background"]}
            proofThreeBookTwoCoverColor={lineItems["Proof (3) Background 2"]}
            proofThreeFoilColor={lineItems["Proof (3) Foil Color"]}
            //Maybe just have an image={} because we know the image??????????????
        /> : 
        <ProofBlock 
            setSelectedImage={setSelectedImage}
            title={lineItems["Type"]}
            foilColor={lineItems["Foil Color"]}
            pageType={lineItems}
            bookSize={lineItems["Size"]}

            proofOneImage={lineItems["Proof"]}
            proofOneCoverColor={lineItems["Background"]}

            proofTwoImage={lineItems["Proof (2)"]}
            proofTwoCoverColor={lineItems["Proof (2) Background"]}
            proofTwoFoilColor={lineItems["Proof (2) Foil Color"]}
            proofImageTwo={lineItems["Proof (2)"]}

            proofThreeImage={lineItems["Proof (3)"]}
            proofThreeCoverColor={lineItems["Proof (3) Background"]}
            proofThreeFoilColor={lineItems["Proof (3) Foil Color"]}
        />;

    // R E T U R N //

    return(
        <div id="proof-section-wrapper-1">
            <div id="proof-section-wrapper-2">
                <div id="proof-section-wrapper-3">
                    <div id="proof-section-titles">
                        <div id="proof-section-titles-2">
                            <div id="order-number-heading">
                                {loading ? <Skeleton width={300} style={{maxWidth:"90%"}}/> : orderNumberHeading + orderNumber}
                            </div>
                            <div id="review-your-proofs-heading">
                                {loading ? <Skeleton width={250}/> : reviewProofsHeading}
                            </div>
                            <div id="book-type-heading">
                                { loading ? null : lineItems["Type"] }
                            </div>
                        </div>
                    </div>
                    
                    {loading ? 
                        
                        // L O A D I N G

                       // <div id="proof-image-skeleton-wrapper-3">
                       //     <div style={{display: "flex", width:"50%"}}>
                       //         <div id="proof-image-skeleton-wrapper">
                       //             <Skeleton id="proof-image-skeleton" height={411} style={{maxWidth:"90%"}}/>
                       //         </div>
                                /* <div style={{display: "block", alignSelf:"center", padding:"20px 0px 50px 0px", width:"30%", position:"relative", right:"45px"}}>
                                    <div style={{display: "flex", flexDirection: "column", textAlign: "left"}}> 
                                        <Skeleton width={200} count={4}/>
                                    </div>
                                </div> */
                       //     </div>
                       // </div> 
                       <div id="new-skeleton-wrapper">
                        <Skeleton height={200}/>
                       </div>
                       
                       :

                        // L O A D E D
                    
                        proofBlockForLineItem
                    
                    }
                </div>
            </div>
        </div>
    );
};


export default ProofSection;