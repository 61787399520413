import React, { useState } from 'react';
import ProofImage from './ProofImage';
import Skeleton from 'react-loading-skeleton';
import ProofDetails from './ProofDetails';
import { useFormikContext, Formik, Field, Form } from 'formik';

const ProofBlock = (props) => {

const { loading, title, foilColor, pageType, bookSize, proofOneImage, proofOneCoverColor, proofOneBookOneCoverColor, proofOneBookTwoCoverColor, proofTwoImage, proofTwoBookOneCoverColor, proofTwoBookTwoCoverColor, proofTwoCoverColor, proofTwoFoilColor, proofThreeImage, proofThreeBookOneCoverColor, proofThreeBookTwoCoverColor, proofThreeCoverColor, proofThreeFoilColor, setSelectedImage } = props;

const firstProofImage = proofOneImage? 
    <ProofImage 
        loading={loading}
        setSelectedImage={setSelectedImage}
        proofRecordID={"1"} // ID For the radio button group
        proofImageRID={"1"} // ID for the individual proof image
        title={title}
        foilColor={foilColor}
        pageType={pageType}
        bookSize={bookSize}
        proofOneImage={proofOneImage ? proofOneImage[0]["url"] : null }
        proofOneFileName={ proofOneImage ? `${proofOneImage[0]["filename"]}.ai` : null }
        proofOneCoverColor={proofOneCoverColor}
        proofOneBookOneCoverColor={proofOneBookOneCoverColor}
        proofOneBookTwoCoverColor={proofOneBookTwoCoverColor}

    /> : null;

const secondProofImage = proofTwoImage? 
    <ProofImage 
        loading={loading}
        setSelectedImage={setSelectedImage}
        pageType={pageType}
        bookSize={bookSize} 
        proofRecordID={"1"} // ID For the radio button group
        proofImageRID={"2"} // ID for the individual proof image
        proofTwoImage={ proofTwoImage ? proofTwoImage[0]["url"]: null }
        proofTwoFileName={ proofTwoImage ? `${proofTwoImage[0]["filename"]}.ai` : null }
        proofTwoFoilColor={proofTwoFoilColor}
        proofTwoCoverColor={proofTwoCoverColor}
        proofTwoBookOneCoverColor={proofTwoBookOneCoverColor}
        proofTwoBookTwoCoverColor={proofTwoBookTwoCoverColor}
    /> : null;

const thirdProofImage = proofThreeImage? 
    <ProofImage 
        loading={loading}
        setSelectedImage={setSelectedImage}
        pageType={pageType}
        bookSize={bookSize}
        proofRecordID={"1"} // ID For the radio button group
        proofImageRID={"3"} // ID for the individual proof image
        proofThreeImage={proofThreeImage ? proofThreeImage[0]["url"] : null }
        proofThreeFileName={proofThreeImage ? `${proofThreeImage[0]["filename"]}.ai` : null }
        proofThreeCoverColor={proofThreeCoverColor}
        proofThreeFoilColor={proofThreeFoilColor}
        proofThreeBookOneCoverColor={proofThreeBookOneCoverColor}
        proofThreeBookTwoCoverColor={proofThreeBookTwoCoverColor}
    /> : null;

const blockSpacing = proofThreeImage? "space-evenly" : proofTwoImage? "space-evenly" : "flex-start";
const blockWidth = proofThreeImage? "100%" : proofTwoImage? "100%" : "auto";
const cardPlacement = proofThreeImage? "center" : proofTwoImage? "center" : "flex-start";
const proofWrapperID = proofThreeImage? "three-proof-wrapper" : proofTwoImage? "two-roof-wrapper" : "one-proof-wrapper";

    return(
        <div id={`${proofWrapperID}`}>
            {/* <div style={{width:"100%", display:"block", textAlign:"left", paddingBottom:"30px", fontStyle:"italic"}}>{title}</div> */}
            <div style={{display: "flex", placeContent:`${blockSpacing}`}}>
                <div style={{display:"flex", placeContent:"center", width:`${blockWidth}`}}> {/* !!! ADD flexFlow:"wrap-reverse" to wrap:""} */}
                    <div id={'1'}>{/*Enter Display Text*/}</div>
                    <div style={{display:"flex", width:"100%"}}>
                    
                    { loading ? 
                        
                        // L O A D I N G

                        <div style={{display: "block", width:"50%"}}>
                            <div style={{padding:"20px"}}>
                                <Skeleton width={130} height={130}/>
                            </div>
                        </div> : 
                        
                        // L O A D E D

                        <div style={{display:"flex", flexDirection:"row", width:"100%", placeContent:`${blockSpacing}`, flexFlow:"wrap"}}>
                            {firstProofImage}
                            {secondProofImage}
                            {thirdProofImage}
                        </div>
    
                    }
                    
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProofBlock;