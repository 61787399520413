import React from 'react';
import Skeleton from 'react-loading-skeleton';
import lunaLogo from '../images/lunaLogo.svg';
import './Popups.scss';

const Logo = (props) => {

    const { loading, logoURL } = props;
    
    return(
        loading ? 
            
        // L O A D I N G

            <div id="logo-skeleton"> 
                <Skeleton height={75}/>
            </div> :

        // L O A D E D
        
            <img 
                id="luna-logo"
                alt="Luna Paper Co Logo"
                src={lunaLogo} 
            />
    );
};

export default Logo;