import React from 'react';
import Logo from './Logo';
import Announcement from './Announcement';
import Paragraphs from './Paragraphs';
import './Popups.scss';

const Headings = (props) => {

    const { loading, logoURL, lineOne, lineTwo, paragraphOne, paragraphTwo, approvalRedirect, revisionsRedirect, pageView, approvalRedirectHeading, revisionsRedirectHeading, orderShippedRedirect, orderShippedRedirectHeading } = props;

    return(
        <div id="headings-wrapper">   
            <Logo
                loading={loading}
                logoURL={logoURL}
            />
            {
                pageView === "Approved" || pageView === "Approved (ATP)" ?
                    <div className="redirect-text">
                        <p id="approval-redirect-heading">{approvalRedirectHeading}</p>
                        <p style={{width:"100%"}}>{approvalRedirect}</p>
                    </div> :
                
                pageView === "Revisions Requested" ?
                    <div className="redirect-text">
                        <p id="approval-redirect-heading">{revisionsRedirectHeading}</p>
                        <p style={{width:"100%"}}>{revisionsRedirect}</p>
                    </div> :

                pageView === "Shipped" ?
                    <div className="redirect-text">
                        <p id="approval-redirect-heading">{orderShippedRedirectHeading}</p>
                        <p style={{width:"100%"}}>{orderShippedRedirect}</p>
                    </div> :

                <div id="headings-content-wrapper">
                    <Announcement
                        loading={loading}
                        lineOne={lineOne}
                        lineTwo={lineTwo}
                    />
                    <Paragraphs
                        loading={loading}
                        paragraphOne={paragraphOne}
                        paragraphTwo={paragraphTwo}
                    />
                </div>
            }
        </div>
    );
};

export default Headings;