import logo from './logo.svg';
import './App.scss';
import Portal from './components/Portal';
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

export default function App() {

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/:orderid" children={<Child/>}/>
        </Switch>
      </Router>
    </div>
  )
}

function Child() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let orderid = useParams();

  return (
    <div>
      <Portal orderno={orderid.orderid}/>
    </div>
  );
}
