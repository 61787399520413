import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
import './Popups.scss';
import Skeleton from 'react-loading-skeleton';
import ApproveButton from './ApproveButton';
import RequestButton from './RequestButton';
import EmailButton from './EmailButton';

//Popup guide: https://react-popup.elazizi.com/react-modal

const Buttons = (props) => {

    const { loading, formValues, proofResponseRID, customerEmail, orderRecordID, customerRecordID, orderLineItems, lineItems, orderNumber, ATP, setPageView, selectedImage, setWrapperHeight, approvalPopupHeading, approvalPopupText, requestChangesPopupHeading, requestChangesPopupText } = props;

    // console.log(formValues); Commented out Sept 24 2021
    // console.log(formValues[1]); Commented out Sept 24 2021

    const imageOne = loading ? null : lineItems["Proof"] ? lineItems["Proof"] : null;
    const imageOneAIName = loading ? null : lineItems["Proof"] ? `${imageOne[0]["filename"]}.ai` : null;

    const imageTwo = loading ? null : lineItems["Proof (2)"] ? lineItems["Proof (2)"] : null;
    const imageTwoAIName = loading ? null : lineItems["Proof (2)"] ? `${imageTwo[0]["filename"]}.ai` : null;

    const imageThree = loading ? null : lineItems["Proof (3)"] ? lineItems["Proof (3)"] : null;
    const imageThreeAIName = loading ? null : lineItems["Proof (3)"] ? `${imageThree[0]["filename"]}.ai` : null;
    
    let approvedFileName = formValues[1] === "1" ? imageOneAIName : formValues[1] === "2" ? imageTwoAIName : formValues[1] === "3" ? imageThreeAIName : null;
    
    // Trim JPG from filename
    let trimJPGExtentsion = true;
    if (trimJPGExtentsion) {
        if(approvedFileName) approvedFileName = approvedFileName.replace(".jpg", "").replace(".JPG", "");
    }

    // console.log(approvedFileName); Commented out Sept 24 2021


    // console.log(understood);
    // console.log(request);

    return(
        loading ? 

        //! Loading State !//

            <div id="buttons-skeleton">
                <Skeleton height={50}/>
            </div> :

        //? Loaded State ?//

            <div id="button-wrapper-1">
                <div id="button-wrapper-2">
                    <ApproveButton 
                        formValues={formValues} 
                        proofResponseRID={proofResponseRID} 
                        orderLineItems={orderLineItems} 
                        approvedFileName={approvedFileName} 
                        orderNumber={orderNumber} 
                        ATP={ATP} 
                        setPageView={setPageView}
                        selectedImage={selectedImage}
                        setWrapperHeight={setWrapperHeight}
                        approvalPopupHeading={approvalPopupHeading}
                        approvalPopupText={approvalPopupText}
                    />
                    <RequestButton 
                        proofResponseRID={proofResponseRID} 
                        selectedFileName={approvedFileName} 
                        orderNumber={orderNumber} 
                        setPageView={setPageView}
                        setWrapperHeight={setWrapperHeight}
                        requestChangesPopupHeading={requestChangesPopupHeading}
                        requestChangesPopupText={requestChangesPopupText}
                    />
                </div>
                {/* <EmailButton customerEmail={customerEmail} orderRecordID={orderRecordID} customerRecordID={customerRecordID}/> */}
            </div>
    );
};

export default Buttons;