import React, { useState, useEffect } from 'react';
import Headings from './Headings';
import ProofSection from './ProofSection';
import ExtraNotes from './ExtraNotes';
import Buttons from './Buttons';
import Footer from './Footer';
import './Popups.scss';
import { useFormikContext, Formik, Field, Form  } from 'formik';

//Test with recfvUxOMY97d0g2V

const Portal = (props) => {

    const {orderno} = props;
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(true);
    const [main, setMain] = useState({});
    const [selectedFileName, setSelectedFileName] = useState('');
    const [defaulted, setDefaulted] = useState({"1":"1"});
    const [pageView, setPageView] = useState("Proofs")
    const [selectedImage, setSelectedImage] = useState('');
    const [wrapperHeight, setWrapperHeight] = useState("auto");

    // console.log(loading); Commented out Sept 24 2021

    //! Below is for AirTable
    useEffect(() => {

        //fetch(`https://api.airtable.com/v0/app8KJUSmraryYyai/Orders/${orderno}`, {
        fetch(`/api/${orderno}`, {
                headers: {
               //     Authorization: "Bearer XXXXXXXXX "
                }
            }
        )
        .then(response => response.json())
        .then(data => {
            setFields(data.fields);
            // setDefaulted('');
            // console.log(data.fields);Commented out Sept 24 2021
            setLoading(false);
            // console.log(loading); Commented out Sept 24 2021
            setPageView(data.fields["Customer Portal View"] === "Revisions Requested" ? "Revisions Requested" : data.fields["Customer Portal View"] === "Approved" ? "Approved" : data.fields["Customer Portal View"] === "Proofs" ? "Proofs" : data.fields["Customer Portal View"] === "Shipped" ? "Shipped" : "Error");
            setWrapperHeight(data.fields["Customer Portal View"] !== "Proofs" ? "100vh": "auto");
            setSelectedImage(data.fields["Proof"][0].url);
            // setSelectedFileName(data.fields.Proof[0]["filename"]);
        })
    }, []);


// const firstImageRID = fields.orderLineItems?.length > 0 && fields.orderLineItems.map((orderLineItem) => 
// (orderLineItem.proofs[0].proofImages[0].recordID));

// console.log(defaulted); Commented out Sept 24 2021

// console.log(fields); Commented out Sept 24 2021
// console.log(useFormikContext()); Commented out Sept 24 2021
// console.log(pageView); Commented out Sept 24 2021
// const somedefaults = '{"434":"6", "431":"5"}';

//const test = JSON.parse(somedefaults);

// const parse = JSON.parse(defaults);

    return(
        
        <div id="portal-main-wrapper" style={{height:`${wrapperHeight}`}}>
            <Formik enableReinitialize initialValues={defaulted}>
                {({ values }) => (
                    <Form id="form-component">
                    <div id="landscape-overflow-wrapper">
                        <div id="header-strip">Personalized goods & more, made for you & yours // Luna Paper Company</div>
                            <Headings
                                loading={loading}
                                logoURL={"https://i.imgur.com/PBC1yaF.png"}
                                lineOne={fields["PORTAL - Paragraph One"]}
                                lineTwo={fields["PORTAL - Announcement Line 1"]}
                                paragraphOne={fields["PORTAL - Announcement Line 2"]}
                                paragraphTwo={fields["PORTAL - Paragraph Two"]}
                                approvalRedirect={fields["Approval Redirect"]}
                                approvalRedirectHeading={fields["Approval Redirect Heading"]}
                                revisionsRedirect={fields["Revisions Requested Redirect"]}
                                revisionsRedirectHeading={fields["Revisions Requested Heading"]}
                                orderShippedRedirect={fields["Order Shipped Redirect"]}
                                orderShippedRedirectHeading={fields["Order Shipped Redirect Heading"]}
                                pageView={pageView}
                            />
                            </div>
                        <div className="page-break"></div>
                        {pageView !== "Proofs" ?  
                        null : 
                        <div id="portal-proof-section-wrapper">
                            <ProofSection
                                lineItems={fields}
                                loading={loading}
                                orderNumberHeading={fields["PORTAL - Order # Heading"]}
                                orderNumber={fields["Order Number"]}
                                reviewProofsHeading={fields["PORTAL - Review Proofs Heading"]}
                                shippingDetailsHeading={fields["PORTAL - Shipping Details Heading"]}
                                name={fields["Customer Name"]}
                                addressLineOne={fields["Address1"]}
                                addressLineTwo={fields["Address2"]}
                                addressCity={fields["City"]}
                                addressState={fields["State"]}
                                addressZip={fields["Zip"]}
                                addressCountry={fields["Country"]}
                                serviceUsed={fields["Order Source"]}
                                estimatedShippingDate={fields["Estimated Delivery"]} //! Is it right to use the estimated delivery date????
                                setSelectedFileName={setSelectedFileName}
                                setSelectedImage = {setSelectedImage}
                            />
                            <ExtraNotes
                                    loading={loading}
                                    noteToCustomer={fields["PORTAL - Note to customer"]}
                                    notesHeading={fields["PORTAL - Notes Heading"]}
                                    noteOneHeading={fields["PORTAL - Note 1 Heading"]}
                                    noteOneText={fields["PORTAL - Note 1 Text"]}
                                    noteTwoHeading={fields["PORTAL - Note 2 Heading"]}
                                    noteTwoText={fields["PORTAL - Note 2 Text"]}
                                    noteTwoUrl={fields["Note 2 URL"]}
                                    noteTwoHref={fields["Note 2 Href"]}
                                    noteThreeHeading={fields["PORTAL - Note 3 Heading"]}
                                    noteThreeText={fields["PORTAL - Note 3 Text"]}
                                    reviewParagraphOne={fields["PORTAL - Review Paragraph 1"]}
                                    reviewParagraphTwo={fields["PORTAL - Review Paragraph 2"]}
                                    lineItems={fields.orderLineItems}
                                    orderNumberHeading={fields["PORTAL - Order # Heading"]}
                                    orderNumber={fields["Order Number"]}
                                    shippingDetailsHeading={fields["PORTAL - Shipping Details Heading"]}
                                    name={fields["Customer Name"]}
                                    addressLineOne={fields["Address1"]}
                                    addressLineTwo={fields["Address2"]}
                                    addressCity={fields["City"]}
                                    addressState={fields["State"]}
                                    addressZip={fields["Zip"]}
                                    addressCountry={fields["Country"]}
                                    reviewProofsHeading={fields.reviewProofsHeading}
                                    serviceUsed={fields["Order Source"]}
                                    estimatedShippingDate={fields["Estimated Delivery"]} //! Is it right to use the estimated delivery date????
                                    estimatedDeliveryNote={fields["Estimated Delivery Note"]}
                                />
                            <Buttons
                                loading={loading}
                                formValues={values}
                                lineItems={fields}
                                proofResponseRID={fields.proofResponseRID}
                                customerEmail={fields.customerEmail}
                                orderRecordID={fields.orderRecordID}
                                customerRecordID={fields.customerRecordID}
                                orderLineItems={fields.orderLineItems}
                                selectedFileName={selectedFileName}
                                orderNumber={orderno}
                                ATP={fields["ATP?"]}
                                setPageView={setPageView}
                                selectedImage={selectedImage}
                                setWrapperHeight={setWrapperHeight}
                                approvalPopupHeading={fields["Approval Popup Heading"]}
                                approvalPopupText={fields["Approval Popup Text"]}
                                requestChangesPopupHeading={fields["Request Changes Popup Heading"]}
                                requestChangesPopupText={fields["Request Changes Popup Text"]}
                            />
                        </div>
                            }
                            
                        <Footer
                            loading={loading}
                            orderNumber={fields["Order Number"]}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default Portal;
