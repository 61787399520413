import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './Popups.scss';

const Paragraphs = (props) => {

    const { loading, paragraphOne, paragraphTwo } = props;

    return(
        <div id="paragraph-one">
        {loading ? <Skeleton count={2}/> :
            <p>
                {paragraphOne} &nbsp;
                {paragraphTwo}
            </p>
        }
        </div>
    );
};

export default Paragraphs;