import React, {useState, useEffect} from 'react';
import Skeleton from 'react-loading-skeleton';
import ProofDetails from './ProofDetails';
import './Popups.scss';

import { SRLWrapper } from "simple-react-lightbox";
import { useFormikContext, Field, Form } from 'formik';
import { withRouter } from 'react-router';

//Lightbox docs: https://github.com/michelecocuccio/simple-react-lightbox

  const options = {
    settings: {
      overlayColor: "rgba(0,0,0,0.75);",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
      disablePanzoom: false,
      disableKeyboardControls: false,
      disableWheelControls: true,
      lightboxTransitionSpeed: 0.1
    },
    buttons: {
      backgroundColor: "#404040",
      iconColor: "white",
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: true,
    },
    caption: {
      showCaption: false
    },
    thumbnails: {
        showThumbnails: false
    }
  }
  
const ProofImage = (props) => {

  const { loading, proofImageRID, proofRecordID, title, foilColor, pageType, bookSize, proofOneImage, proofOneFileName, proofOneCoverColor, proofOneBookOneCoverColor, proofOneBookTwoCoverColor, proofTwoImage, proofTwoFileName, proofTwoBookOneCoverColor, proofTwoBookTwoCoverColor, proofTwoCoverColor, proofTwoFoilColor, proofThreeImage, proofThreeFileName, proofThreeBookOneCoverColor, proofThreeBookTwoCoverColor, proofThreeCoverColor, proofThreeFoilColor, setSelectedImage } = props;
  
  const proofFoilColor =  proofImageRID === '1' ? foilColor : 
                          proofImageRID === '2' ? proofTwoFoilColor : 
                          proofImageRID === '3' ? proofThreeFoilColor : null ;
             
  const proofCoverColor = proofImageRID === '1' ? proofOneCoverColor : 
                          proofImageRID === '2' ? proofTwoCoverColor : 
                          proofImageRID === '3' ? proofThreeCoverColor : null ;

  const proofImage =  proofImageRID === '1' ? proofOneImage : 
                      proofImageRID === '2' ? proofTwoImage : 
                      proofImageRID === '3' ? proofThreeImage : null ;

  const bookOneCoverColor = proofImageRID === '1' ? proofOneBookOneCoverColor : 
                            proofImageRID === '2' ? proofTwoBookOneCoverColor : 
                            proofImageRID === '3' ? proofThreeBookOneCoverColor : null ;

  const bookTwoCoverColor = proofImageRID === '1' ? proofOneBookTwoCoverColor : 
                            proofImageRID === '2' ? proofTwoBookTwoCoverColor : 
                            proofImageRID === '3' ? proofThreeBookTwoCoverColor : null ;

  const proofFileName = proofImageRID === '1' ? proofOneFileName : 
                        proofImageRID === '2' ? proofTwoFileName : 
                        proofImageRID === '3' ? proofThreeFileName : null ;
                        
  const [hoverBackgroundColor, setHoverBackgroundColor ] = useState('white');
  const [hoverTextColor, setHoverTextColor ] = useState('#6e6d7a');
  const [hovered, setHovered ] = useState(false);

  const { values } = useFormikContext(); // Pulling function to pass values to Formik
  const selectedImageRID = values.picked; // Filling values in above function for Formik
  const imageStyling = selectedImageRID === proofImageRID ? 
                          {width:"auto", height:"130px", boxShadow:"0px 0px 0px 7px lightseagreen", maxWidth: "300px", margin:"20px"} : 
                          {height:"auto", maxWidth: "250px", maxHeight:"130px", margin:"20px 20px 0px 20px", placeSelf:"center", padding:"0px 5px"};

  const formikProps = useFormikContext();
  const setRadioButtonValue = () => {
    formikProps.setFieldValue('1', proofImageRID);
    setSelectedImage(proofImage);
  };

  const blockBackgroundColor = formikProps.values[1] === proofImageRID ? 
    "rgb(218 220 217) 0px 0.0625em 0.0625em 0px, rgb(218 220 217) 0px 0.125em 0.5em 10px, rgb(218 220 217) 0px 0px 0px 0px inset" : hovered ? 
    "rgb(218 220 217) 0px 0.0625em 0.0625em 0px, rgb(218 220 217) 0px 0.125em 0.5em 10px, rgb(218 220 217) 0px 0px 0px 0px inset" :
    //"rgb(142 245 201) 0px 0.0625em 0.0625em 0px, rgb(142 242 255) 0px 0.125em 0.5em 8px, rgb(142 245 201) 0px 0px 0px 0px inset" : 
    "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset" ;
//#8EF5C9

  const selectedBackgroundColor = formikProps.values[1] === proofImageRID ? "#A6B9B6" : hovered ? "#A6B9B6" : "white";
  const selectedFontColor = formikProps.values[1] === proofImageRID ? "white" : hovered ? "white" : "#6e6d7a";
  const selectedContent = formikProps.values[1] === proofImageRID ? "Selected ✓" : "S E L E C T";

  // console.log(formikProps.values[1]); Commented out Sept 24 2021
  // console.log(proofOneFileName); Commented out Sept 24 2021
  // console.log(proofTwoFileName); Commented out Sept 24 2021
  // console.log(proofThreeFileName); Commented out Sept 24 2021

    return(

  
      <div className="proofImageBlock" style={{ boxShadow:`${blockBackgroundColor}`}} onClick={() => setRadioButtonValue()} onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <div style={{display: "flex", flexDirection:"column", width:"100%", placeItems:"center"}}>
        
        { loading ? 
          
        // L O A D I N G

          <div style={{padding:"20px"}}><Skeleton width={130} height={130}/></div> :

        // L O A D E D

          <SRLWrapper options={options}>
          <div style={{display:"flex", alignItems:"center", paddingBottom:"10px", justifyContent:"center"}}>
              <img alt="Proof" className="zoom-hand" id="proof-image" src={proofImage} onClick={(e) => {
        e.stopPropagation()}}/>
              </div>
          </SRLWrapper>   
        }

        {/* C R E A T E  R A D I O  B U T T O N */}

        <p id="click-image-to-enlarge">Click Image to Enlarge</p>
        <label style={{display:"none"}}>
          <Field type="radio" name={proofRecordID} value={proofFileName} style={{display: "flex"}}/>
          {proofImageRID}
        </label>


        {/* S E T  R A D I O  B U T T O N  D E F A U L T */}

        </div>
        <ProofDetails 
          proofFoilColor={proofFoilColor}
          proofCoverColor={proofCoverColor}
          bookOneCoverColor={bookOneCoverColor}
          bookTwoCoverColor={bookTwoCoverColor}
          bookSize={ title === "Vow Book" || title === "Wedding Planner" ? null : bookSize }
          pageType={ title === "Vow Book" || title === "Wedding Planner" ? null : pageType }
        />
        <div style={{display:"flex", justifyContent:"center", padding:"20px 0px 15px 0px"}}>
          <div className="selectButton" style={{fontFamily:"'Avenir-Medium', serif", width:"135px", height:"fit-content", padding:"7px 0px 5px 0px", borderRadius:"5px", color:`${selectedFontColor}`, backgroundColor: "#000"}}>{selectedContent}</div>
        </div>
      </div>
    );
};

export default ProofImage;