import React from 'react';
import Skeleton from 'react-loading-skeleton';
import ShippingDetails from './ShippingDetails';
import './Popups.scss';

const ExtraNotes = (props) => {

    const { loading, notesHeading, noteOneHeading, noteOneText, noteTwoUrl, noteTwoHref, noteTwoHeading, noteTwoText, noteThreeHeading, noteThreeText, reviewParagraphOne, reviewParagraphTwo, lineItems, orderNumber, shippingDetailsHeading, name, addressLineOne, addressLineTwo, addressCity, addressState, addressZip, addressCountry, orderNumberHeading, reviewProofsHeading, serviceUsed, estimatedShippingDate, noteToCustomer, estimatedDeliveryNote } = props;

    const customerNote = noteToCustomer? <div><p id="note-to-customer">{noteToCustomer}</p><br></br></div> : null; //Shows note to customer if it exists

    const noteUrl = noteTwoUrl ? <a href={noteTwoHref} target="_blank" rel="noreferrer">{noteTwoUrl}</a> : null; //

    return(

        loading ? 
        
        // L O A D I N G
        
            <div style={{width:"70%", alignSelf:"center"}}>
                <Skeleton count={10}/>
            </div> :


        // L O A D E D

        <div id="extra-notes-wrapper-1">
            <div id="extra-notes-wrapper-2">
                <div id="extra-notes-wrapper-3">
                    {customerNote}
                    <p className="note-heading title" style={{paddingBottom:"10px"}}>
                        {notesHeading}
                    </p>
                    <p>
                        <span className="note-heading">
                            {noteOneHeading}<br></br>
                        </span>
                        {noteOneText}
                    </p>
                    <p>
                        <span className="note-heading">
                            {noteTwoHeading}<br></br>
                        </span>
                        {noteTwoText} {noteUrl}
                    </p>
                    <p>
                        <span className="note-heading">
                        {noteThreeHeading} <br></br>
                        </span>
                        <span>{noteThreeText}</span>
                    </p>
                </div>
                
                <div className="page-break" id="mobile-line-break"></div>
                <div id="shipping-details-mega-wrapper">
                    <ShippingDetails
                                loading={loading}
                                shippingDetailsHeading={shippingDetailsHeading}
                                customerName={name}
                                addressLineOne={addressLineOne}
                                addressLineTwo={addressLineTwo}
                                addressCity={addressCity}
                                addressState={addressState}
                                addressZip={addressZip}
                                addressCountry={addressCountry}
                                serviceUsed={serviceUsed}
                                estimatedShippingDate={estimatedShippingDate}
                                estimatedDeliveryNote={estimatedDeliveryNote}
                            />
                        </div>
                        
                <div className="page-break padding-top" id="mobile-line-break"></div>
                        </div>
                <div id="review-paragraphs-wrapper">
                        <br></br>
                    <div>
                        {reviewParagraphOne}
                    </div>
                        <br></br>
                    <div>
                        {reviewParagraphTwo}
                    </div>
                </div>
            </div>
    );
};

export default ExtraNotes;