import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
import './Popups.scss';

const RequestButton = (props) => {

    const [request, setRequest] = useState("");
    const [sendingRequest, setSendingRequest] = useState(false);
    const { proofResponseRID, selectedFileName, orderNumber, setPageView, setWrapperHeight, requestChangesPopupHeading, requestChangesPopupText } = props;
    const [revButtonContent, setRevButtonContent] = useState("Submit");
    const revisionButtonContent = sendingRequest ? <img src="https://i.imgur.com/tEToq5L.gif" style={{width:"30px", height:"30px"}}/> : "Submit";
    const buttonDisabled = request.length===0 || revButtonContent !== "Submit" ?  true : false;
    const [ displayError, setDisplayError ] = useState("none");
    const buttonAlignment = displayError === "none" ? "flex-end" : "space-between";

    const headers = {
        'QB-Realm-Hostname': 'lunapaperco.quickbase.com',
        'User-Agent': 'FileService_Integration_V2.1',
        'Authorization': 'QB-USER-TOKEN b5bam5_pncm_0_c95nb5jcffgwwuusq6mic4m7miw',
        'Content-Type': 'application/json'
    };

    const currentTime = new Date().toISOString();
    
    const revisionRequestHandler = () => {
        addRevisionRecord();
        updateMainRecord();
    }

    const addRevisionRecord = () => {
        // setSendingRequest(true);
        // setRevButtonContent(<img src="https://i.imgur.com/tEToq5L.gif" style={{width:"20px", height:"20px"}}/>);
        fetch(`/api/${orderNumber}/revisioncreaterecord/${encodeURIComponent(request)}/${selectedFileName}`, {

            // This is the older front-end API call

            // method: 'POST',
            // headers: {
            //     Authorization: "Bearer XXXXXXXXXX",
            //     "Content-Type": "application/json"
            // },
            // body: JSON.stringify({
            //     "records": [
            //         {
            //             "fields": {
            //                 "Order Number": [orderNumber],
            //                 "Revisions Requested File Name": selectedFileName,
            //                 "Revisions Requested": request
            //             }
            //         }
            //     ]
            // })
        })
        .then(response => response.json())
        // .then(data => console.log(data)) Commented out Sept 24 2021
        .then(() => setSendingRequest(false))
        .then(() => setRevButtonContent("Submitted!"))
        .then(() => setPageView("Revisions Requested"))
        .then(() => setWrapperHeight("100vh"))
        .catch((error) => {
            setRevButtonContent("Submit")
            setDisplayError("block")
        });
    };
    
    const updateMainRecord = () => {
        setRevButtonContent(<img src="https://i.imgur.com/tEToq5L.gif" style={{width:"20px", height:"20px"}}/>);
        fetch(`/api/${orderNumber}/revisionupdaterecord/${encodeURIComponent(request)}`, {
            headers: {}
            }
        )
        .then(response => response.json())
        // .then(data => console.log(data)) Commented out Sept 24 2021
        .then(() => setSendingRequest(false))
        .then(() => setRevButtonContent("Submitted!"));
        // .catch((error) => {
        //     setRevButtonContent("Submit")
        //     setDisplayError("block")
        // });
    };

    return(
        <Popup trigger={<button className="request-revision-button">Request Changes</button>} modal>
        {/*Change button class to requestButton to revert*/}
            {close => (
                <div className="content">
                    <div style={{paddingBottom:"10px"}} id="close-wrapper">
                        <div id="popup-header">
                            {requestChangesPopupHeading}
                        </div>
                        <button className="close" onClick={close}>
                            &times;
                        </button>
                    </div>
                    <div id="request-box-wrapper">
                        <p id="revision-modal-content">{requestChangesPopupText}</p>
                        <textarea
                            name="requested-changes"
                            id="request-changes-box"
                            type="text"
                            value={request}
                            onChange={e => setRequest(e.target.value)}
                            >
                        </textarea>
                    </div>
                    <div id="checkbox-wrapper" style={{display: "flex", justifyContent:`${buttonAlignment}`, padding:"10px"}}>
                    <div style={{width:"max-content", paddingLeft:"5px", paddingRight:"10px", color: "red", display:`${displayError}`}}>Error: Please email us if you are not redirected to the 'Thank you' page.</div>          
                        <div className="popup-approve-button">
                            <button disabled={buttonDisabled} className="approveButton" style={{width:"110px"}} onClick={() => revisionRequestHandler()}>
                                {revButtonContent}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    );
};


export default RequestButton;