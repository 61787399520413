import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './Popups.scss';

const Announcement = (props) => {
    const { loading, lineOne, lineTwo } = props;
    
    return(
        loading ?

        // L O A D I N G

            <div style={{alignSelf:"center"}}>
                <div>
                    <Skeleton width={200} style={{maxWidth:"90%"}}/>
                </div>
                <div style={{alignItems:"center"}}>
                    <Skeleton width={300} style={{maxWidth:"90%"}}/>
                </div>
            </div> :
        
        // L O A D E D
 
            <div id="announcement-content">
                <p id="announcement-content-top">
                    {lineOne}
                </p>
                <p id="announcement-content-bottom">
                    {lineTwo}
                </p>  
            </div>
    
            
    );
};

export default Announcement;