import React from 'react';

const Footer = (props) => {

    const { loading, orderNumber } = props;

    return(

         loading ? 
            null :
            <div id="footer-section">
                <div id="luna-copyright">
                    © &nbsp;Luna Paper Company
                </div>
                <div id="other-questions-block">
                    Other questions? 
                    <br></br>
                    <a href={`mailto:proofs@lunapaperco.com?subject=Order#:%20` + orderNumber}>
                        proofs@lunapaperco.com
                    </a>
                </div>
            </div>
    );
};


export default Footer;